import { request } from './request'

// 获取通知配置列表
export function getNotifyConfListRequest (params) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/notify/conf/list',
    method: 'get',
    params
  })
}

// 更新通知配置
export function updateNotifyConfRequest (info) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/notify/conf/update',
    method: 'post',
    data: { ...info }
  })
}

// 更新通知配置状态
export function updateNotifyConfStatusRequest (info) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/notify/conf/update/status',
    method: 'post',
    data: { ...info }
  })
}

// 添加通知配置
export function addNotifyConfRequest (info) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/notify/conf/add',
    method: 'post',
    data: { ...info }
  })
}

// 删除任务
export function delNotifyConfRequest (id) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: `/notify/conf/${id}`,
    method: 'delete'
  })
}

export function getConfigSetting (params) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/config/detail',
    method: 'get',
    params
  })
}

export function updateConfigSetting (params) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/config/update',
    method: 'post',
    data: params
  })
}

export function genShortChain (params) {
  return request({
    baseUrl: window.g.NOTIFY_URL,
    url: '/short-chain/generate',
    method: 'post',
    data: params
  })
}
