<template>
  <div>
    <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
      <el-form-item>
        <el-select v-model="listQuery.msgType" @clear="cancelMsgType" clearable placeholder="选择类型">
          <el-option
            v-for="item in msgTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button  style="margin-left: 10px" type="primary" @click="getNotifyConfList">查询</el-button>
        <el-button
          type="primary"
          icon="el-icon-plus"
          style="margin-left: 10px"
          @click="showDialog"
        >添加
        </el-button>
      </el-form-item>
    </el-form>
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="msgType" label="类型" width="100px">
        <template slot-scope="scope">{{ scope.row.msgType | msgTypeLabel }}</template>
      </el-table-column>
      <el-table-column label="子类型" prop="subType" :formatter="formatSubType"></el-table-column>
      <el-table-column prop="desc" label="描述">
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="{row}">
          <el-switch v-model="row.status" active-text="启用" inactive-text="停用" :active-value=1
                     :inactive-value=2 @change="changeStatus(row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="200px;">
        <template slot-scope="{row}">
          <el-row>
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="updateNotifyConf(row)"
            >修改</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="delNotifyConf(row)"
            >删除</el-button
            >
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="form.id ? '修改通知配置' : '添加通知配置'"
      :visible.sync="dialogFormVisible"
    >
      <!-- form表单 :model属性，这个属性的作用是,把表单的数据收集到那个对象的身上 ，将来表单验证，也需要这个属性-->
      <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
        <el-form-item label="类型" label-width="100px" prop="msgType">
          <el-select v-model="form.msgType" style="width:100%" placeholder="请选择通知类型">
            <el-option
              v-for="item in msgTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="子类型" label-width="100px" prop="subType">
          <span v-if="form.msgType==0">请先选择类型</span>
          <el-select v-if="form.msgType==1" v-model="form.subType" style="width:100%" placeholder="请选择通知子类型">
            <el-option
              v-for="item in systemTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-if="form.msgType==2" v-model="form.subType" style="width:100%" placeholder="请选择通知子类型">
            <el-option
              v-for="item in orderTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-if="form.msgType==3" v-model="form.subType" style="width:100%" placeholder="请选择通知子类型">
            <el-option
              v-for="item in rewardTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-if="form.msgType==4" v-model="form.subType" style="width:100%" placeholder="请选择通知子类型">
            <el-option
              v-for="item in placardTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" label-width="100px" prop="desc">
          <el-input v-model="form.desc"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdateNotifyConf"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  updateNotifyConfRequest,
  addNotifyConfRequest,
  getNotifyConfListRequest,
  delNotifyConfRequest,
  updateNotifyConfStatusRequest
} from '../../../network/notify'

export default {
  name: 'NotifyDefinePane',
  filters: {
    msgTypeLabel (value) {
      switch (value) {
        case -1:
          return '全部'
        case 1:
          return '系统通知'
        case 2:
          return '订单通知'
        case 3:
          return '中奖通知'
        case 4:
          return '系统公告'
      }
    },
    systemTypeLabel (value) {
      switch (value) {
        case 1:
          return '游客首次登录'
        case 2:
          return '用户注册'
        case 3:
          return '邀请用户成功注册'
      }
    },
    orderTypeLabel (value) {
      switch (value) {
        case 1:
          return '发货通知'
        case 2:
          return '退款通知'
        case 3:
          return '返利通知'
      }
    },
    rewardTypeLabel (value) {
      switch (value) {
        case 1:
          return '夺宝中奖通知'
        case 2:
          return '大转盘中奖通知'
        case 3:
          return '拆红包中奖通知'
      }
    },
    placardTypeLabel (value) {
      switch (value) {
        case 1:
          return '夺宝中奖通知'
      }
    }
  },
  data () {
    return {
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: '',
      disabled: false,
      // 收集签到规则信息
      form: {
        desc: '',
        msgType: -1,
        subType: ''
      },
      // 表单验证规则
      rules: {
        desc: [{ required: true, message: '请输入通知描述', trigger: 'blur' }],
        msgType: [
          { required: true, message: '请选择通知类型', trigger: 'change' }
        ],
        subType: [
          { required: true, message: '请选择子通知类型', trigger: 'change' }
        ]
      },
      // 通知类型
      msgTypes: [{
        label: '全部',
        value: -1
      }, {
        label: '系统通知',
        value: 1
      }, {
        label: '订单通知',
        value: 2
      }, {
        label: '中奖通知',
        value: 3
      }, {
        label: '系统公告',
        value: 4
      }],
      orderTypes: [
        {
          label: '发货通知',
          value: 1
        },
        {
          label: '退款通知',
          value: 2
        },
        {
          label: '返利通知',
          value: 3
        }
      ],
      systemTypes: [
        {
          label: '游客首次登录',
          value: 1
        },
        {
          label: '用户注册',
          value: 2
        },
        {
          label: '邀请用户成功注册',
          value: 3
        }
      ],
      rewardTypes: [
        {
          label: '夺宝中奖通知',
          value: 1
        },
        {
          label: '大转盘中奖通知',
          value: 2
        },
        {
          label: '拆红包中奖通知',
          value: 3
        }
      ],
      placardTypes: [
        {
          label: '新用户公告',
          value: 1
        }
      ],
      listQuery: {
        msgType: -1
      }
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getNotifyConfList()
  },
  methods: {
    formatSubType (row) {
      switch (row.msgType) {
        case 1:
          switch (row.subType) {
            case 1:
              return '游客首次登录'
            case 2:
              return '用户注册'
            case 3:
              return '邀请用户成功注册'
            default:
              return row.subType
          }
        case 2:
          switch (row.subType) {
            case 1:
              return '发货通知'
            case 2:
              return '退款通知'
            case 3:
              return '返利通知'
            default:
              return row.subType
          }
        case 3:
          switch (row.subType) {
            case 1:
              return '夺宝中奖通知'
            case 2:
              return '大转盘中奖通知'
            case 3:
              return '拆红包中奖通知'
            default:
              return row.subType
          }
        case 4:
          switch (row.subType) {
            case 1:
              return '新用户公告'
            default:
              return row.subType
          }
        default:
          return row.subType
      }
    },
    cancelMsgType () {
      this.listQuery.msgType = ''
    },
    getNotifyConfList () {
      getNotifyConfListRequest(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取通知列表失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
      })
    },
    updateNotifyConf (row) {
      this.dialogFormVisible = true
      this.form = { ...row }
    },
    delNotifyConf (row) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delNotifyConfRequest(row.id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除失败', 'error')
          }
          this.alertMessage('删除成功', 'success')
          this.getNotifyConfList()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    showDialog () {
      this.dialogFormVisible = true
      this.form = { desc: '', msgType: 1, subType: '' }
    },
    updateNotifyConf2Srv () {
      updateNotifyConfRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改通知配置失败', 'error')
        }
        this.alertMessage('修改通知配置成功', 'success')
        this.getNotifyConfList()
      })
    },
    addNotifyConf2Srv () {
      addNotifyConfRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加通知配置失败', 'error')
        }
        this.alertMessage('添加通知配置成功', 'success')
        this.getNotifyConfList()
      })
    },
    addOrUpdateNotifyConf () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        if (this.form.id) {
          this.updateNotifyConf2Srv()
        } else {
          this.addNotifyConf2Srv()
        }
      })
    },
    changeStatus (row) {
      updateNotifyConfStatusRequest({ id: row.id, status: row.status }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改通知配置状态失败', 'error')
        }
        this.alertMessage('修改通知配置状态成功', 'success')
        this.getNotifyConfList()
      })
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
