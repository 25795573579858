<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-input placeholder="消息类型描述" v-model="listQuery.desc" clearable @clear="search">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="onAdd">添加消息类型</el-button>
      </el-col>
    </el-row>
    <el-dialog
      title="添加类型"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose">
      <el-form :model="form" :rules="formRules" ref="formRef" label-width="100px">
        <!--<el-form-item label="上级消息类型">
          <el-select @clear="clearSelect" v-model="addCategoryForm.parentId" clearable filterable :placeholder="placeholder" style="width: 100%">
            <el-option v-for="option in options" :key="option.value" :label="option.label" :value="option.value"></el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item label="子消息类型" prop="desc">
          <el-input type="Number" v-model.number="form.subType"></el-input>
        </el-form-item>
        <el-form-item label="类型描述" prop="desc">
          <el-input v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="图标" label-width="100px" prop="logo" v-if="!form.msgType">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :headers="imgUploadHeaders"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addNotifyConfRequest } from '../../../network/notify'

export default {
  name: 'NotifyTypePane',
  data () {
    return {
      listQuery: {
        page: 1, // 当前页
        size: 10 // 每页显示多少条数据
      },
      addDialogVisible: false, // 控制添加分类对话框的显示与隐藏
      form: {
        desc: '',
        msgType: '',
        subType: '',
        icon: ''
      },
      options: [], // 所有选项
      placeholder: '请选择消息类型',
      formRules: {
        desc: [
          {
            required: true,
            message: '请输入消息描述',
            trigger: 'blur'
          }
        ],
        subType: [
          {
            required: true,
            message: '请输入消息值',
            trigger: 'blur'
          }
        ]
      },
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: '',
      disabled: true
    }
  },
  methods: {
    search () {
    },
    onAdd () {
      this.addDialogVisible = true
      /* getCategoriesListRequest().then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取分类列表失败', 'error')
        }
        const data = res.data.data.list
        this.options = []
        for (const item of data) {
          this.options.push({ label: item.name, value: item.categoryId })
        }
      }) */
    },
    add () {
      console.log('add form:', this.form)
      // eslint-disable-next-line no-unreachable
      this.$refs.formRef.validate(valid => {
        if (!valid) {
          return this.alertMessage('添加参数有误', 'error')
        }
        this.addDialogVisible = false
        if (this.form.msgType === '') {
          this.form.msgType = 0
        } else {
          this.form.msgType = parseInt(this.form.msgType)
        }
        this.form.subType = parseInt(this.form.subType)
        addNotifyConfRequest(this.form).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('添加通知配置失败', 'error')
          }
          this.alertMessage('添加通知配置成功', 'success')
          this.getNotifyConfList()
        })
      })
    },
    addCategoryDialogClose () {
      this.$refs.formRef.resetFields()
      this.imageUrl = ''
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.form.icon = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    },
    handleImgRemove (file) {
      this.form.icon = ''
      this.imageUrl = ''
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
